import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import ProfileImg from 'assets/images/no_profile2.png'
import '@metamask/legacy-web3';
import { toast } from 'react-toastify';

import action_config from './config/config';
import { isEmpty } from "../actions/common";
import { userRegister, kycadd, getallkyc } from "../actions/axioss/user.axios";

toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}



export default function Kyc(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const dispatch = useDispatch()

  const { push } = useHistory();
  const { token, payload, isAdmin } = useSelector(state => state.LoginReducer.User)



  console.log("gdgdfgdrtrfffgdfg", token);

  const initialValue = {
    DisplayName: payload?.DisplayName,
    EmailId: payload?.EmailId,
    CustomUrl: payload?.CustomUrl,
    pdffile1: '',
    pdffile2: '',
    pdffiles: [],
    Mobileno: '',
    WalletAddress: payload?.WalletAddress,

    CustomUrl: payload?.CustomUrl,

  }
  console.log('asgdgdtttttsg', payload)

  useEffect(() => {
    setFormValue(initialValue)
  }, [payload])



  const [formValue, setFormValue] = useState(initialValue)
  console.log("formasvalrur", formValue);
  const [ValidateError, SetValidateError] = useState('')
  const [onchangeimg, setOnchangeimg] = useState('')
  const [imageVal, setImageVal] = useState('')
  const [disablebtn, setDisablebtn] = useState(0)
  // const [pdfimage1, setpdfimage1] = useState({})
  const [pdfss, setpdfss] = useState([])
  console.log("fgfgfgfgfgfgfgfgfg", pdfss);
  // const [kyclist, setkyclist] = useState([])
  // console.log("trtrtrtrt", kyclist);
  // const []
  console.log("onchangeimage", imageVal);
  const {
    DisplayName,
    WalletAddress,
    EmailId,
    CustomUrl,
    pdffile1,
    pdffile2,
    pdffiles,

    Mobileno,
    Type
  } = formValue

  console.log("walletAddress", WalletAddress, payload)

  useEffect(() => {
    try {
      if (payload?.kyc == "Approved") {
        // toast.success(`your kyc is already ${payload?.kyc}`)
        push(`/profile/${formValue?.CustomUrl ? formValue?.CustomUrl : WalletAddress}`)
      }
      else {
        push(`/kyc/${formValue?.CustomUrl ? formValue?.CustomUrl : WalletAddress}`)
      }
    } catch (error) {
      console.log("errrr", error);
    }

  }, [])

  useEffect(() => {
    kycall()
  }, [])

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   getProfile()

  // }, [payload?.Profile]);

  // const getProfile = async () => {
  //   console.log("adsda", Profile);
  //   if (payload?.Profile) {
  //     console.log('shdsh', payload?.Profile)
  //     var profileimage = action_config.IMG_URL + '/user/' + payload.WalletAddress + '/profile/' + payload.Profile;
  //     console.log("adsda", profileimage);
  //     setOnchangeimg(profileimage);
  //   }
  // }


  const onChange = (e) => {
    setDisablebtn(0)
    SetValidateError("")
    const { value, id } = e.target

    if (id == 'CustomUrl') {
      var formData = { ...formValue, ...{ ['CustomUrl']: value.toString().replace(/\s/g, '').toLowerCase() } }
    }

    else if (id == "pdffile1" || id == "pdffile2") {


      const reader = new FileReader();
      var files = e.target.files[0]
      console.log("filesfghfhbhgjh", files)
      var formData = { ...formValue, ...{ [id]: files } }
      setFormValue(formData)

    }
    else if (id == "pdffiles") {
      const reader = new FileReader();
      var files = e.target.files
      console.log("filesfghfhbhgjh", files)
      var formData = { ...formValue, ...{ [id]: files } }
      setFormValue(formData)
      setpdfss(files)
    }
    else
      var formData = { ...formValue, ...{ [id]: value } }
    setFormValue(formData)
  }

  const kycall = async () => {
    console.log("wewererere");
    var data = {
      WalletAddress: WalletAddress
    }
    var listed = await getallkyc(data)
    console.log("rerererer", listed?.data);
    //  setkyclist(listed.data)
    if (listed?.data?.verifiedStatus == "Approved") {
      toast.success(`your kyc is already ${payload?.kyc}`)
      // push(`/profile/${formValue?.CustomUrl}`)
      push(`/profile/${formValue?.CustomUrl ? formValue?.CustomUrl : WalletAddress}`)
    }
    else {
      console.log("rrrrrrrrrrrrrrr");

      // push(`/kyc/${formValue?.CustomUrl ? formValue?.CustomUrl : WalletAddress }`)

    }
  }

  const Validation = useCallback((data) => {
    console.log("profileloooo", data);
    console.log("hhhhhhhhh", formValue);

    let ValidateError = {}

    if (!(/^\d+$/).test((formValue.Mobileno))) {
      ValidateError.Mobileno = "mobile is invalid. only allowed number format";
    }
    // var fileName = file.name;
    console.log("dfgdfgfdg", formValue?.pdffile1?.name);

    if (formValue?.pdffile1) {
      var fileNameExt = formValue?.pdffile1?.name?.substr(formValue?.pdffile1?.name.lastIndexOf(".") + 1);
      if (fileNameExt != 'pdf')
        ValidateError.pdffile1 = "file is invalid. only allowed pdf format";
    }
    if (formValue?.pdffile2) {
      var fileNameExt = formValue?.pdffile2?.name?.substr(formValue?.pdffile2?.name.lastIndexOf(".") + 1);
      if (fileNameExt != 'pdf')
        ValidateError.pdffile2 = "file is invalid. only allowed pdf format";
    }
    if (pdfss && pdfss?.length > 0) {
      Object.values(pdfss).map((val) => {
        var fileNameExt = val?.name?.substr(val?.name.lastIndexOf(".") + 1);
        if (fileNameExt != 'pdf')
          ValidateError.pdfss = "file is invalid. only allowed pdf format";
      })

    }

    // if (!(/^[a-z0-9_()\-\[\]]+\.pdf$/i).test((formValue.pdffile2))) {

    //   ValidateError.pdffile2 = "file is invalid. only allowed pdf format";
    // }
    // if (!(/^[a-z0-9_()\-\[\]]+\.pdf$/i).test((formValue.pdffiles))) {

    //   ValidateError.pdffiles = "file is invalid. only allowed pdf format";
    // }
    // }
    if (!(formValue.pdffile1)) {

      ValidateError.pdffile1 = "pdffile is required";
    }
    if (!(formValue.pdffile2)) {

      ValidateError.pdffile2 = "pdffile is required";
    }
    if (isEmpty(formValue.Mobileno)) {
      ValidateError.Mobileno = "mobile no is required";
    }

    if (isEmpty(formValue.DisplayName)) ValidateError.DisplayName = 'DisplayName Required'
    if (isEmpty(formValue.CustomUrl)) ValidateError.CustomUrl = 'CustomUrl Required'

    if (isEmpty(formValue.EmailId)) ValidateError.EmailId = 'EmailId Required'
    if (formValue.EmailId && !(action_config.EMAIL).test(formValue.EmailId)) ValidateError.EmailId = 'Invalid Email Format'
    SetValidateError(ValidateError)
    return ValidateError
  })
  // const [ValidateError, SetValidateError] = useState('')

  // const FormSubmit = async () => {
  //   const id = toast.loading("Updating Progress..")
  //   let error = Validation(formValue)
  //   console.log('errsubbb', formValue, error, !isEmpty(error), 'dfdsg', ValidateError)
  //   if (!isEmpty(error)) {
  //     setDisablebtn(1)
  //     SetValidateError(error)
  //     toast.update(id, { render: 'Validation Failed', type: 'error', isLoading: false, autoClose: 1000, position: 'top-center' })

  //   }
  //   else {
  //     console.log("form value", formValue)

  //     let Resp = await userRegister(formValue)
  //     console.log("Checking the Validation", Resp);
  //     if (Resp.success === 'success') {
  //       toast.update(id, { render: 'Saved successfully', type: 'suceess', isLoading: false, autoClose: 1000, position: 'top-center' })
  //       setDisablebtn(0)

  //       dispatch({
  //         type: 'Register_Section',
  //         Register_Section: {
  //           User: {
  //             payload: Resp.data,
  //             isAdmin: Resp.data?.isAdmin ? Resp.data?.isAdmin == 'Yes' ? true : false : isAdmin,
  //             token: Resp.token ? Resp.token : token
  //           }
  //         }
  //       })
  //       if (Resp.data.CustomUrl) document.cookie = 'user' + "=" + Resp.data.CustomUrl + ";" + ";path=/";
  //       push(`/my-item/${formValue.CustomUrl}`)
  //     }
  //     else {
  //       setDisablebtn(1)
  //       toast.update(id, { render: 'Validation Failed', type: 'error', isLoading: false, autoClose: 1000, position: 'top-center' })
  //       SetValidateError({ ...ValidateError, ...Resp.validate })
  //     }
  //   }

  // }
  // const handlechange = async (e) => {
  //   console.log("erererer", e.target.files);
  //   e.preventDefault();
  //   const { id } = e.target;
  //   if (id == "file") {
  //     const reader = new FileReader();
  //     var files = e.target.files
  //     console.log("files in cms", files)
  //     setpdfimage1({ ...files })
  //     setpdfimage2({ ...files })
  //   } else {
  //     const { id, value } = e.target;
  //     console.log("value", value)
  //     let formdata = { ...formValue, ...{ [id]: value } }
  //     setFormValue(formdata)
  //     console.log("formval", formdata)
  //   }

  // }
  const handlesubmit = async () => {


    var value = Validation();

    if (!isEmpty(value)) {
      console.log("value", value);
      SetValidateError(value);
      toast.error("fix all validation");
    }
    else {
      var Formdatas = new FormData()
      Formdatas.append("DisplayName", formValue.DisplayName)
      Formdatas.append("WalletAddress", formValue.WalletAddress)
      Formdatas.append("EmailId", formValue.EmailId)
      Formdatas.append("CustomUrl", formValue.CustomUrl)
      Formdatas.append("pdffile1", formValue.pdffile1)
      Formdatas.append("pdffile2", formValue.pdffile2)
      console.log("pfdddd", pdfss, Object.values(pdfss));
      Object.values(pdfss).map((items) => {
        console.log("trytuytjuy", items);
        Formdatas.append("pdffiles", items)
      })
      Formdatas.append("Mobileno", formValue.Mobileno)
      Formdatas.append("action", "add")

      var resp = await kycadd(Formdatas);
      console.log("dfdertfgdg", resp);
      if (payload?.kyc != "Approved") {
        if (resp.status == true) {
          toast.success(resp.msg)
          push(`/profile/${formValue?.CustomUrl}`)
        }
        else
          toast.error(resp.msg)
        push(`/profile/${formValue?.CustomUrl}`)
      }
      else {
        toast.error(resp.msg)
        push(`/profile/${formValue?.CustomUrl}`)
      }

    }

  }


  return (
    <div className="inner_header edit_profile">

      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid" /></Link></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>


        <div className="container container-lg mt-3 mt-md-5">
          <GridContainer>
            {/* <GridItem xs={12} sm={12} md={2}>


            </GridItem> */}
            <GridItem xs={12} sm={12} md={12}>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-5">
                    <label className="primary_label" htmlFor="name">Display Name</label>
                    <input type="text"
                      className="form-control primary_inp"
                      placeholder="Enter your display name"
                      id="DisplayName" value={DisplayName} onChange={onChange} required
                      autoComplete="off"

                    />
                    {ValidateError && ValidateError.DisplayName && <span className="error_msg">{ValidateError.DisplayName}</span>}


                  </div>
                  <div className="form-group col-md-5">
                    <label className="primary_label" htmlFor="desccription">Email</label>
                    <input type="text"
                      className="form-control primary_inp"
                      id="EmailId" value={EmailId} onChange={onChange} required
                      name="email"
                      autoComplete="off"

                      placeholder="Your email for marketplace notifications" />
                    {ValidateError && ValidateError.EmailId && <span className="error_msg">{ValidateError.EmailId}</span>}


                  </div>


                </div>

                <div className="form-row">

                  {/* <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Email</label>
                    <input type="text"
                      className="form-control primary_inp"
                      id="EmailId" value={EmailId} onChange={onChange} required
                      name="email"
                      autoComplete="off"

                      placeholder="Your email for marketplace notifications" />
                    {ValidateError && ValidateError.EmailId && <span className="error_msg">{ValidateError.EmailId}</span>}


                  </div> */}
                  {/* <div className="form-group col-md-6 editsdprofile">
                    <label className="primary_label" htmlFor="name">Custom Name</label>
                    <div className="input-group">

                      <div className="input-group-prepend">
                        <span className="input-group-text pl-0 min_h_45_px min_h_35" id="basic-addon2">{action_config?.FRONT_URL.slice(0,20).concat("...")}/profile/</span>

                      </div>
                      <input type="text"
                        className="primary_inp"
                        placeholder="Enter unique name only... EX.Nila,Ronaldo..."
                        id="CustomUrl" disabled="true"value={CustomUrl}  onChange={onChange} required 
                        autoComplete="off"

                       
                         />
                  {ValidateError && ValidateError.CustomUrl && <span className="error_msg">{ValidateError.CustomUrl}</span>}

                    </div>
                  </div> */}
                  <div className="form-group col-md-5">
                    <label className="primary_label" htmlFor="desccription">Mobile No</label>
                    <input type="text"
                      className="form-control primary_inp"
                      id="Mobileno" value={formValue?.Mobileno} onChange={onChange} required
                      name="Mobileno"
                      autoComplete="off"

                      placeholder="enter your mobile number" />
                    {ValidateError && ValidateError.Mobileno && <span className="error_msg">{ValidateError.Mobileno}</span>}


                  </div>

                  <div className="form-group col-md-5">
                    <label className="primary_label" htmlFor="desccription">pdf1</label>
                    <input type="file"
                      className="form-control primary_inp"
                      id="pdffile1"
                      name="pdffile1"
                      // value = {formValue?.pdffile1}

                      onChange={onChange}
                    />
                    {ValidateError && ValidateError.pdffile1 && <span className="error_msg">{ValidateError.pdffile1}</span>}

                  </div>
                  <div className="form-group col-md-5">
                    <label className="primary_label" htmlFor="desccription">pdf2</label>
                    <input type="file"
                      className="form-control primary_inp"
                      id="pdffile2"
                      name="pdffile2"
                      // value = {formValue?.pdffile2}
                      onChange={onChange}
                    />
                    {ValidateError && ValidateError.pdffile2 && <span className="error_msg">{ValidateError.pdffile2}</span>}

                  </div>

                  <div className="form-group col-md-5">
                    <label className="primary_label" htmlFor="desccription">pdffiles<span className="text-muted">(Optional)</span></label>
                    <input type="file" multiple
                      className="form-control primary_inp"
                      id="pdffiles"
                      name="pdffiles"
                      // value = {formValue.pdffiles}
                      onChange={onChange}
                    />
                    {ValidateError && ValidateError.pdfss && <span className="error_msg">{ValidateError.pdfss}</span>}
                  </div>

                </div>







                <div className="mt-3">
                  <Button className="create_btn" onClick={handlesubmit} >Update Kyc</Button>
                </div>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
