import React, { useState, useEffect } from "react";
import { Notifications, AccountBalanceWallet, WbSunny, Brightness3 } from '@material-ui/icons';
import { Link, useHistory, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Scrollbars } from 'react-custom-scrollbars';
import CopyToClipboard from "react-copy-to-clipboard";
import Avatars from "views/Avatar";
import User from "../../assets/images/user_icons.png"
import refresh from "../../assets/images/refresh.png"
import logout from "../../assets/images/logout.png"
import metamaskolb from "../../assets/images/wallet_011.png"
import walleticon from "../../assets/images/walleticon.png"
import moment from 'moment'
import Config from "views/config/config";
import { SearchAction } from "actions/axioss/nft.axios"
import ImgAudVideo from "views/separate/ImgAudVideo";
import NotificationCard from "views/separate/NotificationCard"
import { Token_MyList_Func } from 'actions/axioss/user.axios'
import { network } from 'views/config/networkconfig';



import { useDispatch, useSelector } from 'react-redux'

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
toast.configure();
const useStyles = makeStyles(styles);
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
export default function HeaderLinks(props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();


  const wallet = useSelector(state => state.LoginReducer.AccountDetails);
  const { payload } = useSelector(state => state.LoginReducer.User)
  console.log("payloadhead", payload);
  const { Categorys } = useSelector((state) => state.LoginReducer);
  console.log("category headerlinks", Categorys)


  const [Searchdata, SetSearch] = React.useState(null);
  const [val, setval] = useState("");
  const [cursor, setcursor] = React.useState('');


  const { web3p, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );


  // for notificaions
  const [value, setValue] = React.useState('All');
  const [Tokens, SetTokens] = useState({ 'owned': { 'loader': true, page: 1, list: [] } })

  useEffect(() => {
    if (typeof Tokens[value] == 'undefined') {
      Tokens[value] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, value);
    }

  }, [value, accountAddress])



  const Explore = async (data, tab) => {
    var page = data ? data : (Tokens[value]?.page)
    var SendDATA = {
      TabName: "activity",
      limit: 12,
      // CustomUrl: customurl,
      NFTOwner: accountAddress,
      page: page ?? 1,
      from: 'myItem',
      cursor: cursor,
      filter: value
    }

    console.log("to data", SendDATA)
    let Resp = await Token_MyList_Func(SendDATA)
    console.log('sfgfhgfs', value, tab, Resp, Tokens)
    if (Resp?.success == 'success' && Resp.data.length > 0) {
      setcursor(Resp?.cursor)

      SetTokens({
        ...Tokens, ...{
          [value]: {
            list: [...Tokens[value].list, ...Resp.data],
            loader: (Resp.data.length == 0 || (SendDATA.TabName == "usercollection" && Resp.cursor == null)) ? false : true,
            page: Tokens[value].page
          }
        }
      })
    }
  }




  const toggletheme = () => {

    document.getElementById("root").classList.toggle('dark_theme');
    var usebody = document.getElementsByClassName("mobile_nav");
    for (var j = 0; j < usebody.length; j++) {
      usebody[j].classList.toggle('dark_theme')
    }


  };
  const toggleUsermenu = () => {
    var useclass = document.getElementsByClassName("usemneu_dd");
    for (var i = 0; i < useclass.length; i++) {
      useclass[i].classList.toggle('d-none')
    }
  }

  var tab = 'activity';
  const toggleSearchmenu = async (event) => {
    var useclass = document.getElementsByClassName("searchmneu_dd");
    if (event.target.value.length == 1) {
      for (var c = 0; c < useclass.length; c++) {
        useclass[c].classList.remove('d-none');

      }
    }
    if (event.target.value.length == 0) {
      for (var c = 0; c < useclass.length; c++) {
        useclass[c].classList.add('d-none');

      }
    }
  }


  const walletDisconnect = async () => {

    // localStorage.clear()
    localStorage.removeItem("accountInfo")
    localStorage.removeItem("walletConnectType")
    indexedDB.deleteDatabase('WALLET_CONNECT_V2_INDEXED_DB')
    dispatch({
      type: 'Account_Section',
      Account_Section: {
        AccountDetails: {
          accountAddress: "",
          tokenBalance: 0,
          coinBalance: 0
        }
      }
    })
    window.location.reload();
  }


  /// search bar

  const searchFunc = async (value) => {
    if (value) {
      setval(value);
      console.log("value search", value)
      var Resp = await SearchAction({
        keyword: value,
        limit: 3,
        page: 1,
        from: "search",
      });
      console.log("Search Resp", Resp);
      if (Resp) {
        SetSearch(Resp);
      }
    }
  };



  return (
    <>


      {console.log("check wallet", wallet.accountAddress, payload)}
      <List className={classes.list + " main_navbar"}>




        {/*        
         <ListItem className={classes.listItem}>
          <Link className={classes.navLink} to="/explore/All">Explore</Link>
        </ListItem> */}
        {/* <Link to="/explore/All"> */}
        {/* <ListItem className={classes.listItem + " menu_dropdown explore_icon_hide dropdown_header_ul noti_ul exploreLinks"}>
          <CustomDropdown
            noLiPadding
            buttonText={<div className="noti_online align-items-center" onClick={()=>history.push("/explore/All")}>
              Explore </div>}
            dropdownList={[
              <div className="noti_parent">

                <Scrollbars className="nto_scrol_div">
                  <ul className="noti_ul_dd">
                    <li className="px-3">
                      

                        <Link to={`/explore/All`}>
                          <span>View All</span>
                        </Link>
                      
                
                    </li>



                    {Categorys&&Categorys.map((item)=>{return(
                            

                              <Link to={`/explore/${item?.label}`}>
                                <> <li className="px-3"><span>{item?.label}</span></li></>
                              </Link>
                            
                        )})} 




                  </ul>
                </Scrollbars>
                <p className="no_noti d-none">No new notifications</p>


              </div>
            ]}
          />
        </ListItem> */} <ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul user_dd_ul ml-0"}>
          <div className="search_inp_group">
            <div className="search_inp_group_append">
              <i className="fas fa-search header_search_icon"></i>
            </div>

            <input type="text" className="search_inp" placeholder="Search here..." onChange={(e) => {
              console.log("e,target.valeu", e.target.value)
              toggleSearchmenu(e);
              searchFunc(e.target.value)

            }} />

          </div>

          <div className="noti_parent noti_parnt_user searchmneu_dd searchmneu_dd_1 d-none " id="searchmneu_dd">
            {Searchdata?.token?.data?.length > 0 ||
              Searchdata?.user?.msg?.length > 0 ||
              Searchdata?.drop?.msg?.length > 0 ||
              Searchdata?.artist?.msg?.length > 0 ? (
              <Scrollbars style={{ height: 350 }} className="nto_scrol_div">
                {Searchdata.token?.data?.length > 0 && (
                  <>
                    {<p className="font_we_700">Items</p>}
                    <ul className="noti_ul_dd">
                      {Searchdata.token?.data.map(
                        (product) => (
                          <li className="px-3" onClick={() => {
                            setval("");
                            SetSearch();
                            history.push(
                              `/info/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}`
                            );
                          }}>
                            <div className="media"  >

                              <ImgAudVideo
                                file={`${Config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`}
                                origFile={`${Config.IMG_URL}/nft/${product?.NFTCreator}/Original/NFT/${product?.OriginalFile}`}
                                thumb={`${Config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT_THUMB/${product?.CompressedThumbFile}`}
                                type={
                                  product?.CompressedFile
                                    ? product?.CompressedFile?.includes(".webp")
                                      ? "image"
                                      : product?.CompressedFile.includes(".webm")
                                        ? "video"
                                        : "audio"
                                    : product?.CompressedFile
                                }
                              />
                              <div className="media-body flex_body">
                                <div>
                                  <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc mr-1">{((product?.NFTName).length > 8) ? (product?.NFTName).slice(0, 8).concat("...") : product?.NFTName}</p>
                                  <p className="mt-0 banner_desc_user mb-0 font_12 not_banner_dessc mr-1">

                                    {/* <span>  0Cake </span> */}


                                  </p>
                                </div>
                              </div>
                            </div>

                          </li>
                        )
                      )}





                    </ul></>)}
                {Searchdata.user?.msg?.length > 0 && (

                  <>
                    <p className="font_we_700">Users</p>

                    <ul className="noti_ul_dd">

                      {Searchdata.user?.msg.map(
                        (product) => (
                          <li className="px-3" onClick={() => {
                            setval("");
                            history.push(
                              `/my-item/${product?.CustomUrl}`
                            );
                          }}>
                            <div className="media">
                              {(product?.Profile) ?
                                <img src={`${Config.IMG_URL}/user/${product?.WalletAddress}/profile/${product?.Profile}`}
                                /> : <Avatars item="img-fluid mr-2 user_ul_new align-self-center nnnnnnn" />}



                              <div className="media-body flex_body">
                                <div>
                                  {(product?.DisplayName) ?
                                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc mr-1">
                                      {String(product?.DisplayName).length > 16 ? (product?.DisplayName).slice(0, 16).concat("...") : product?.DisplayName}
                                    </p> :
                                    <p className="mt-0 banner_desc_user mb-0 font_14 not_banner_dessc mr-1">{String(product?.WalletAddress).slice(0, 16).concat("...")}</p>}

                                </div>

                              </div>
                            </div>

                          </li>
                        )
                      )}

                    </ul></>
                )}


              </Scrollbars>) : <p className="font_we_700">No Items Found</p>}
            <div className="text-center">
              <button className="btn new_btn_grad" tabIndex="0" type="button" ><span><Link to={`/search/${val}`}>Search</Link></span></button>
            </div>
          </div>


        </ListItem>

        <ListItem className={classes.listItem}>
          <NavLink className={classes.navLink} to="/explore/All">Explore</NavLink>
        </ListItem>


        {/* </Link> */}
        <ListItem className={classes.listItem}>
          <NavLink className={classes.navLink} to="/activity">Activity</NavLink>
        </ListItem>
        <ListItem className={classes.listItem}>
          <NavLink className={classes.navLink} to={`/my-item/${payload?.CustomUrl}`}>My Items</NavLink>
        </ListItem>
        <ListItem className={classes.listItem}>
          <NavLink className={classes.navLink} to="/how-it-works">FAQ</NavLink>
        </ListItem>{console.log("payload?.kyc",payload?.kyc)}
        <ListItem className={classes.listItem}>
          <Button
          className="create_btn"
           onClick={() => {
            // if (payload?.kyc == "Approved") {

              history.push(`/create`)
            //   // toast.success("you have Approved, so create nft")
            // } else {
            //   toast.success("you have rejected, so not create nft")
            // }
          }

          }
          >
            Create
          </Button>
        </ListItem>





        <ListItem className={classes.listItem + " menu_dropdown explore_icon_hide dropdown_header_ul noti_ul noti_tb_trans"}>
          <CustomDropdown
            noLiPadding
            buttonText={<div><Notifications className="menu_icons " />
              <span className="icon_txt">Notifications</span>

              <span className="green_circle_dot"></span>


            </div>}
            dropdownList={[
              <div className="noti_parent_class">
                <p className="noti_head">Notifications</p>
                <Scrollbars style={{ height: 210 }}>
                  <ul className="noti_ul_dd">




                    {Tokens[value]?.list.length < 0 ?
                      (
                        Tokens[value]?.list.map((item, index) => (
                          <li className="px-3" >
                            <NotificationCard
                              item={item}
                            /> </li>))) : <li className="px-3" ><h2>No Items Found</h2></li>

                    }








                  </ul>
                </Scrollbars>


              </div>
            ]}
          />
        </ListItem>


        <ListItem className={classes.listItem}>
          {wallet && wallet.accountAddress ?

            <Button className={classes.navLink + " create_btn"} data-toggle="modal" onClick={() => { walletDisconnect() }}>
              Disconnect
            </Button> :
            <Button className={classes.navLink + " create_btn"} data-toggle="modal" data-target="#connect_modal">
              Connect Wallet
            </Button>}
        </ListItem>
        {wallet && wallet.accountAddress &&

          <ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul noti_ul noti_tb_trans headusericonss"}>
            <CustomDropdown
              noLiPadding
              buttonText={<div className=" headusericons " ><img src={walleticon} /></div>}
              dropdownList={[
                <div className="noti_parent_class">

                  <Scrollbars style={{ height: 200 }}>
                    <div className="heaserbalancelist">

                      <div className="walletconnted">
                        <div className="userwallet">
                          <img src={(payload?.Profile) ?
                            Config.IMG_URL + '/user/' + payload.WalletAddress + '/profile/' + payload.Profile
                            : User} /> <span>  </span>
                          {/* <p2>{String(wallet?.accountAddress).slice(0,10).concat("...")}</p2> */}
                          <p2>{(payload?.DisplayName) ? String(payload?.DisplayName).slice(0, 12).concat("...") : String(payload?.WalletAddress).slice(0, 12).concat("..")}</p2>

                          {/* <span>My Wallet <i class="fas fa-chevron-down"></i></span> */}
                          {/* <li><img src={metamaskolb} className="metamasksd"/> Metamask</li> */}

                          {/* <ul>
                 <Link to={`/my-item/${payload.CustomUrl}`}><li><i class="fas fa-sign-out-alt"></i> My Items</li></Link> 
                  <Link to={`/profile/${String(wallet?.accountAddress).toLowerCase()}`}><li><i class="fas fa-sync-alt"></i> Edit Pofie</li></Link>
                </ul> */}
                        </div>
                      </div>

                      <div className="heaserbalance">
                        <h4>Total Balance</h4>
                        <h5>{String((wallet?.coinBalance / 10 ** 18).toFixed(4)) + " " + network[wallet?.currentChainId ? wallet?.currentChainId : Config.default_chainId]?.currencySymbol}</h5>
                      </div>
                      {/* <p className="headeraddbalane">Disconnect</p> */}
                    </div>
                  </Scrollbars>


                </div>
              ]}
            />
          </ListItem>}
        {/* <ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul noti_ul noti_tb_trans headusericonss"}>
          <CustomDropdown
            noLiPadding
            buttonText={ <div className=" headwawleticons " ><img src={walleticon}/></div>}
            dropdownList={[
              <div className="noti_parent_class">
                <p className="noti_head">Notifications</p>
                <Scrollbars style={{ height: 210 }}>
                 
                </Scrollbars>


              </div>
            ]}
          />
        </ListItem> */}

      </List>

    </>);
}
