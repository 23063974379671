import React, { useState, useEffect, useRef, useContext, } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import $ from "jquery";
import './index.css';


import Icon1 from "assets/images/metamaskolb.png";
import Icon3 from "assets/images/wallet_connect.png";
import Icon2 from "assets/images/trust_wallet.png";

// pages for this product
import Home from "views/Home.js";
import Create from "views/Create.js";
import CreateSingle from "views/Create-single.js";
import About from "views/About.js";
import EditProfile from "views/edit-profile.js";
import Kyc from "views/kyc.js";
import Myitems from "views/my-items.js";
import Following from "views/following.js";
import Privacypolicy from "views/Privacypolicy.js";
import Terms from "views/Termsofservice.js";
import Explore from "views/explore.js";
import Info from "views/info.js";
import community from "views/communty"
import { GetNftCookieToken } from '../src/actions/axioss/nft.axios'
import collectionlist from "views/collectionList.js"

import AddCollection from "../src/views/addcollection"
import UserCollection from "../src/views/usercollections"

import Activity from "views/activity.js";
import Search from "views/search.js";
import comingsoon from "views/comingsoon.js";
import Faq from "views/faq.js";
// added by me
import '@metamask/legacy-web3'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import { connectWallet, getServiceFees } from './views/hooks/useWallet.js';
import { isEmpty } from 'actions/common.js';
import { userRegister, GetUserCookieToken } from '../src/actions/axioss/user.axios'
import { Category, Currency, USDPRICE, TOKENPRICE } from 'actions/axioss/cms.axios'
import Homepage from "views/Homepage.js";
import { getAllChain } from "actions/axioss/nft.axios";

import config from './views/config/config';


import Certificatepage from "views/certificates"



toast.configure();



export default function Indexs(props) {

  const dispatch = useDispatch();

  const wallet = useSelector(state => state.LoginReducer.AccountDetails);
  const { payload } = useSelector(state => state.LoginReducer.User)
  const { web3 } = useSelector(state => state.LoginReducer.AccountDetails);
  //g("User details",payload,wallet)

  useEffect(async () => {
    console.log("web3", web3, wallet);
    if (localStorage.getItem("walletConnectType") && wallet.accountAddress == '') {
      // var chain = web3 ? await web3.eth.getChainId() : undefined
      initialConnectWallet(localStorage.getItem("walletConnectType"))
    }
    getInitialSeviceFee();
    Categorys1();


  }, []);


  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', async function () {
        handleAccountChange();

      })

    }
  }, [])


  const handleAccountChange = async (...args) => {
    let chain = web3 ? await web3.eth.getChainId() : undefined
    initialConnectWallet(localStorage.walletConnectType, chain);
  }

  const initialConnectWallet = async (type, chain) => {
    window.$('#connect_modal').modal('hide');
    const id = toast.loading(type + " Wallet Connecting...")
    var chasincheck = chain ? chain : undefined
    console.log("asdfsdfsdfsdf", chain);
    var accountDetails = await connectWallet(type, chasincheck)
    //g("accountDetails",accountDetails)
    if (!isEmpty(accountDetails)) {
      var resp = await setWalletAddress('InitialConnect', accountDetails.accountAddress, type)
      console.log("resp", resp)
      if (resp?.success == 'success') {
        toast.update(id, { render: resp.msg, type: resp.success, autoClose: 1000, isLoading: false, })
        var Allnetwork = await getAllChain()
        console.log("chainlist usewallet", accountDetails);
        if (Allnetwork?.status) {
          accountDetails.chainList = Allnetwork?.data;
        }
        dispatch({
          type: "Account_Section",
          Account_Section: { AccountDetails: accountDetails }
        })
        CurrencyList(accountDetails);
        getInitialSeviceFee(accountDetails);
      }
      else {
        toast.update(id, { render: "Connect Wallet", type: 'error', autoClose: 1000, isLoading: false, })
      }

    }
    else toast.update(id, { render: "Try Again", type: 'error', autoClose: 1000, isLoading: false, })
  }


  const setWalletAddress = async (type, walletAddress, walletType) => {
    if (walletAddress) {
      var NewMethod = {
        Type: type,
        WalletAddress: walletAddress,
        WalletType: walletType,
      };
      let Resp = await userRegister(NewMethod);
      console.log("kfwrmjvioegoer", Resp);
      if (Resp?.success == 'success') {
        dispatch({
          type: 'Register_Section',
          Register_Section: {
            User: {
              payload: Resp.data
            }
          }
        })
        console.log("ajdfjwejf3iueff3", Resp?.token);
        document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";
        GetUserCookieToken();
        GetNftCookieToken();
        return Resp
      }
      else return Resp
    }
    else return { success: 'error', msg: 'No Address Detected.. Check Your Wallet' }

  }

  const Categorys1 = async () => {

    let Resp = await Category();

    if (Resp?.data) {
      //g("category data",Resp?.data)
      var sendda = [];
      var categorylist = (Resp?.data) ? (Resp?.data).filter((item => item.hideShow != "hidden")) : []
      // var data = (Resp.data || []).map((item) => {
      var data = (categorylist).map((item) => {

        sendda.push({
          label: item.name,
          value: item.name,
          description: item.description,
        });
      });
      //   setCategory(sendda)
      //g("to dispatch categories",sendda)
      dispatch({
        type: "Register_Section",
        Register_Section: {
          Categorys: sendda,
        },
      });
    }
  };

  const CurrencyList = async (Details) => {
    let Resp = await Currency();
    console.log("Resp@123", Resp);
    var CurrentNetwork = Details.currentChainId
    console.log("ckjadsjfsfwwe", CurrentNetwork, Details);
    var CurrentCurreny = CurrentNetwork == "80001" ? "Mumbai" : "Binance"

    if (Resp?.msg) {
      // //g("Respmsg",Resp?.msg[0]?.CurrencyDetails,(Resp?.msg[0]?.CurrencyDetails).filter((item)=>(item.deleted != true || item.deleted != 1)));
      var currenySelected = []
      var mapdata = await Resp?.msg?.map((data) => {
        console.log("adajndkasdas", data);

        if (CurrentCurreny == data.label) {
          console.log("Asdnsioufasdma", data.CurrencyDetails);
          return currenySelected.push(data)
        }
      })

      console.log("adncjdsnkjfsd", mapdata, currenySelected);
      var currencylist = (currenySelected[0]?.CurrencyDetails) ? (currenySelected[0]?.CurrencyDetails)?.filter((item) => (item.deleted != true || item.deleted != 1)) : []
      console.log("acmsd kljcvsd", currencylist);

      var sen = [];
      var datas = await Promise.all(
        // Resp?.msg[0]?.CurrencyDetails ||
        //   []?.map(async (data) => {

        (currencylist)?.map(async (data) => {
          if (data.label == "ETH" || data.label == CurrentCurreny)
            var USD = await USDPRICE(data.label);
          else var USD = await TOKENPRICE(data.address);
          sen.push({
            value: data.value,
            label: data.label,
            address: data.address,
            usd: USD ? USD : 0,
            decimal: data.decimal,
          });
        })
      );
      //g("sen",sen,datas.length,datas)
      dispatch({
        type: "Register_Section",
        Register_Section: {
          // currency: datas.length > 0 ? datas : sen,
          currency: datas.length > 0 ? sen : [],

        },
      });
    }
  };

  const getInitialSeviceFee = async (accountDetails) => {
    let chainid = accountDetails?.currentChainId ? accountDetails?.currentChainId : config.default_chainId;
    console.log("chainidchainid11111", chainid, web3);
    var fees = await getServiceFees(chainid, accountDetails?.web3);
    //g("getServiceFees",fees);
    console.log("feeees", fees, chainid);
    if (fees) {
      dispatch({
        type: 'ServiceFees',
        ServiceFees_Section: {
          ServiceFees: fees,
        },
      });
    }
  };





  return (
    <>
      <BrowserRouter basename="/" >
        <Switch>
          <Route path='/info/:network/:Contract/:Owner/:Id' component={Info} />
          {/* <Route path='/info' component={Info} /> */}
          <Route path="/search/:key" component={Search} />
          <Route path="/create-single" component={CreateSingle} />
          <Route path="/create-multiple" component={CreateSingle} />
          <Route path='/create' component={Create} />
          <Route path="/my-item/:customurl" component={Myitems} />
          <Route path="/activity" component={Activity} />
          <Route path="/myitem" component={Myitems} />
          <Route path='/explore' component={Explore} />
          <Route path='/explore/:category' component={Explore} />
          <Route path='/profile/:user' component={EditProfile} />
          <Route path="/cms/:page" component={Terms} />
          <Route path="/how-it-works" component={Faq} />
          <Route path='/kyc/:user' component={Kyc} />
          <Route path='/certificates' component={Certificatepage} />
          {/* 
                    <Route path="/terms-and-conditions" component={Terms} />
                   
                   <Route path="/privacy-policy" component={Privacypolicy} />  */}
          <Route path="/" component={Homepage} />

          {/* <Route path="/homepage" component={Home} /> */}
          {/* <Route exact path="/*" component={Home}>
                        <Redirect to="/" />
                    </Route> */}


        </Switch>
      </BrowserRouter>



      <div className="modal fade primary_modal" id="connect_modal" role="dialog" aria-labelledby="connect_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false" >
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="connect_modalLabel">Connect Your Wallet</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h3>If you don't have a wallet yet,you can select a provider and create one new</h3>
              <div className="connectfles row">
                <div className="videossd col-md-6 col-sm-12 col-12" id="MetaMask" onClick={() => initialConnectWallet("MetaMask")}>
                  <img src={Icon1} />
                </div>
                {/* <div className="videossd col-md-6  col-sm-12 col-12" id="WalletConnect" onClick={() => initialConnectWallet("WalletConnect")}>
                  <img src={Icon2} />
                </div> */}
                <div className="videossd col-md-6  col-sm-12 col-12" id="WalletConnect" onClick={() => initialConnectWallet("WalletConnect")}>
                  <img src={Icon3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}


