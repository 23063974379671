import React, { useState, useEffect, useMemo } from "react";
import axios from 'axios'
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useHistory, useLocation } from "react-router-dom";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
// import masonary1 from 'assets/images/masonary_04.png'
// import Loader from 'assets/images/loader.png';
import Card from "../assets/images/carddet.jpg"
import User from "../assets/images/LeBron.png"
import Cardlayer from "../assets/images/cardlayer.png"
import Lakers from "../assets/images/lakers.png"
import Profile from "../assets/images/masonary_03.png"
import BNB from "../assets/images/card_bnb.png"
import Star from "../assets/images/staricons.png"
import Mask from "../assets/images/drop.jpg"
import '@metamask/legacy-web3'
import Select from 'react-select'
import { toast } from "react-toastify";
import $ from "jquery"
import Convert from 'views/separate/Convert';
import moment from "moment";
// import CLockModal from "views/separate/CLockModal.js";

import useContractProviderHook from "../actions/contractProviderHook";
import config from "./config/config";
import { isEmpty } from "../actions/common";
import { ImgValidation } from "../actions/validations";
import { network } from "./config/networkconfig";
import { CollectionByCreator, nftCreate, getAllChain } from "../actions/axioss/nft.axios";
import {
  nftNameValidation,
  NFTImageUpload,
  CreateNFT,
  lazymintaxios,
  signcallaxios
} from "../actions/axioss/nft.axios";
import { card } from "assets/jss/material-kit-react";
import { checkChain } from "./hooks/useWallet";
import Web3 from 'web3';
import Web3Utils from 'web3-utils';
import randomInteger from 'random-int';
import generator from 'generate-password';

var web3s = new Web3(config.RPC_URL)
toast.configure();
const dashboardRoutes = [];
const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function CreateSingle(props) {

  const priceoption = [

  ];

  const classes = useStyles()
  const { ...rest } = props;


  const { buyerFees, sellerFees } = useSelector(
    (state) => state.LoginReducer.ServiceFees
  );
  const { web3p, web3, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );

  const { payload } = useSelector(
    (state) => state.LoginReducer.User
  );

  console.log("dfgrgrthhhhhh", payload);
  let formatTime = (time) => {
    return String(time).padStart(2, "0");
  };
  const history = useHistory();
  const urllocation = useLocation();
  const dispatch = useDispatch();
  console.log("cehck location", urllocation.pathname.split("/")[1])
  const { currency, Categorys } = useSelector((state) => state.LoginReducer);
  console.log("currenct create page", Categorys)

  const Wallet_Details = useSelector(state => state.LoginReducer.AccountDetails);
  console.log("asdawdsasdeasd", Wallet_Details);

  const initialTokenValue = {
    NFTName: "",
    NFTQuantity: "1",
    NFTOrginalImage: null,
    NFTOrginalImagePreview: null,
    NFTThumpImage: "",
    NFTThumpImagePreview: "",
    NFTOrginalImageIpfs: "",
    NFTThumpImageIpfs: "",
    CompressedFile: "",
    CompressedThumbFile: "",
    NFTDescription: "",
    PutOnSaleType: "UnlimitedAuction",
    PutOnSale: false,
    NFTPrice: 0,
    NFTMinimumBid: 0,
    ClockTime: "",
    EndClockTime: "",
    NFTRoyalty: 0,
    NFTProperties: "",
    NFTCreator: accountAddress,
    NFTOwner: accountAddress,
    HashValue: "",
    MetFile: "",
    MetaData: "",
    ContractAddress: "",
    ContractType: "",
    Category: "",
    CoinName: "",
    UnlockContent: "",
    CollectionName: "OLBNFT",
    CollectionNetwork: Wallet_Details?.chainDetails?.currencySymbol,
    CollectionSymbol: "",
  }
  // console.log("Categorys[0].label,",Categorys[0].label,);
  const [NFTFormValue, setNFTFormValue] = useState(initialTokenValue);
  const [ValidateError, SetValidateError] = useState({});
  const [FormButton, SetFormButton] = useState("start");
  const [ApproveButton, SetApproveButton] = useState("start");
  const [UploadButton, SetUploadButton] = useState("stop");
  const [MintButton, setMintButton] = useState("stop");
  const [theme, setTheme] = useState(false);
  const [show8, setShow8] = useState(false);
  const [location, SetLocation] = useState("");
  const [OpenPopup, SetOpenPopup] = useState("");
  const [CreateCollectionState, SetCreateCollectionState] = useState([]);
  const [lazy, setmin] = useState(false)
  const [listforsalenow, setlistforsalenow] = useState(false);
  const [list, setlist] = useState("timed_auction");
  const [start, setstart] = useState("");
  const [end, setend] = useState("");

  const [previewFile, setPreviewFile] = useState(Card)
  const [previewThumbnail, setPreviewThumbnail] = useState(Card)
  const [fileType, setFileType] = useState("")
  const [unlock, setUnlock] = useState(false);  // for cursor cehckox

  const [sign, SetSignTxt] = useState()
  const [close, setCloseStatus] = useState()
  const [approve, setApproveCallStatus] = useState()
  const [Randomname, setRandomName] = useState("")
  const [_nonce, setNonceHash] = useState("")
  const [signature, setSignatureHash] = useState("")
  const [signcalls, setSign] = useState(false)
  console.log("fsfsf", signature);
  // const [previewFile,setPreviewFile] = useState(arrow)

  console.log("Start Time", start);
  console.log("End time", end);
  const ContractCall = useContractProviderHook();

  console.log("chek state update", NFTFormValue)
  useEffect(() => {
    let path = (urllocation.pathname.split("/")[1] == "create-single") ? "Single" : "Multiple";
    SetLocation(path);
    let type = (urllocation.pathname.split("/")[1] == "create-single") ? 721 : 1155;
    let Addr = (urllocation.pathname.split("/")[1] == "create-single") ? network[Wallet_Details.currentChainId]?.singleContract : network[Wallet_Details?.currentChainId]?.multipleContract;
    setNFTFormValue({
      ...NFTFormValue,
      ...{
        ["ContractAddress"]: Addr,
        ["ContractType"]: type,
        CollectionNetwork: Wallet_Details?.chainDetails?.chain,
        ChainId: Wallet_Details?.currentChainId
      },
    });
    CollectionByCreate({
      Type: (urllocation.pathname.split("/")[1] == "create-single") ? 721 : 1155,
      Creator: accountAddress,
    });

    console.log("cehck nft", NFTFormValue, NFTFormValue?.NFTOrginalImage?.type)

  }, [accountAddress, urllocation]);


  const YouWillGet = useMemo(() => {
    return ContractCall.price_calculation(NFTFormValue.NFTPrice);
  }, [NFTFormValue.NFTPrice]);

  const setClockValue = (data, date) => {
    setNFTFormValue({
      ...NFTFormValue,
      ...{ [data]: moment(date).format("YYYY-MM-DD h:mm:ss a") },
    });
  };



  useEffect(() => {
    if (payload && payload?.kyc !== "Approved") {
      history.push(`/profile/${payload?.CustomUrl ? payload?.CustomUrl : localStorage.getItem("accountInfo")}`)
      toast.error(`your kyc is ${payload?.kyc},please try again`)
    }

  }, [payload])

  // input field onchange function
  const onChange = (e, type) => {
    console.log("e,type", e.target, type)
    SetFormButton("start");
    SetValidateError({})
    if (e && e.target && !e.target.files) {
      const { files, value, id, name } = e.target;
      console.log("value, id", value, id)
      setNFTFormValue({ ...NFTFormValue, ...{ [id]: value } });
    }
    if (e.target.files) {

      var files = e.target.files;

      // var reader = new FileReader()
      var file = files[0];
      console.log("File", file);
      var validExtensions = (type == "Orginal") ?
        [
          "png",
          "gif",
          "webp",
          "mp4",
          "PNG",
          "jpg",
          "JPEG",
          "jpeg",
          "JPG",
          "mp3",
          "aac",
          "AAC",
          "flac",
          "FLAC",
          "WEBM",
          "webm",
          "ogv",
          "OGV",
        ] :
        [
          "png",
          "gif",
          "webp",
          "PNG",
          "jpg",
          "JPEG",
          "jpeg",
          "JPG"

        ]

      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);

      var fileformat = file.type.split("/")  // image/video/audio
      var filetype = fileformat[0]

      console.log(
        "kgsdgh",
        fileNameExt,
        validExtensions,
        validExtensions.some((val) => fileNameExt === val),
        files[0],
        URL.createObjectURL(files[0]),
        fileformat, filetype
      );
      if (!validExtensions.some((val) => fileNameExt === val)) {
        toast.error(
          "Only these file types are accepted : " + validExtensions.join(", ")
        );
      }
      if (type == "Orginal") {
        console.log("original set", files[0])
        NFTFormValue["NFTOrginalImage"] = files[0];
        setPreviewFile(URL.createObjectURL(files[0]))
        setFileType(filetype)
        // setNFTFormValue({
        //   ...NFTFormValue,
        //   ...{ ["NFTOrginalImage"]: files[0] },
        // });

      }
      if (type == "Thump") {
        console.log("thumb set")

        NFTFormValue["NFTThumpImage"] = files[0];
        setPreviewThumbnail(URL.createObjectURL(files[0]))

        // setNFTFormValue({
        //   ...NFTFormValue,
        //   ...{ ["NFTThumpImage"]: files[0] },
        // });

      }
    }
    if (NFTFormValue.NFTCreator == "") {
      setNFTFormValue({
        ...NFTFormValue,
        ...{ ["NFTCreator"]: accountAddress, ["NFTOwner"]: accountAddress },
      });
    }
  };

  //NFT mint validation function
  const Validation = (data) => {
    try {
      let ValidateError = {};
      const {
        NFTName,
        NFTOrginalImage,
        NFTThumpImage,
        NFTPrice,
        EndClockTime,
        ClockTime,
        NFTRoyalty,
        Category,
        PutOnSaleType,
        PutOnSale,
        CoinName,
        NFTQuantity,
        ContractType
      } = data;

      // console.log(
      //   "sfgh",
      //   NFTName,
      //   NFTOrginalImage,
      //   NFTOrginalImage.type?.includes("video")
      // );
      if (!NFTRoyalty) ValidateError.NFTRoyalty = "Royalty Required";
      else if (isEmpty(NFTRoyalty))
        ValidateError.NFTRoyalty = "Royalty Must Be Greate Than 0";
      else if (isNaN(NFTRoyalty) === true)
        ValidateError.NFTRoyalty = "Royalty must be a number";
      if (!NFTName) ValidateError.NFTName = "TokenNameRequired";
      if (
        (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") &&
        isEmpty(NFTPrice)
      )
        ValidateError.NFTPrice = "NFTPrice Required";
      if (
        (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") &&
        !CoinName
      )
        ValidateError.CoinName = "CoinName Required";
      if (PutOnSaleType == "TimedAuction" && !ClockTime)
        ValidateError.ClockTime = "ClockTime Required";
      if (PutOnSaleType == "TimedAuction" && !EndClockTime)
        ValidateError.EndClockTime = "EndClockTime Required";
      console.log("Category", Category);
      if (!Category) ValidateError.Category = "Category Required";
      if (ContractType === 1155 || ContractType === '1155') {
        if (!NFTQuantity) ValidateError.NFTQuantity = "NFTQuantity Required";
      }
      if (!NFTOrginalImage)
        ValidateError.NFTOrginalImage = "OriginalFileRequired";
      else {
        if (ImgValidation(NFTOrginalImage, "pro"))
          ValidateError.NFTOrginalImage = ImgValidation(NFTOrginalImage, "pro");
        if (
          (NFTOrginalImage.type.includes("video") ||
            NFTOrginalImage.type.includes("audio")) &&
          !NFTThumpImage
        )
          ValidateError.NFTThumpImage = "ThumbFile Required";
        else if (NFTThumpImage)
          if (ImgValidation(NFTThumpImage, "thumb"))
            ValidateError.NFTThumpImage = ImgValidation(NFTThumpImage, "thumb");
      }
      // console.log('dkfasgas',NFTPrice,Number(NFTPrice) < 0)
      if (isNaN(NFTPrice) === true)
        ValidateError.NFTPrice = "NFT Price Should Be a Number";
      else if ((PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") && (isNaN(NFTPrice) === true)) {
        console.log("NFTPrice", NFTPrice, Number(NFTPrice))
        ValidateError.NFTPrice = "NFTPrice should be above Zero"
      };

      if (ContractType === 1155 || ContractType === '1155') {
        console.log('hiiii115555', ((Number(NFTQuantity) % 1) !== 0))
        if (((Number(NFTQuantity) % 1) !== 0)) {
          ValidateError.NFTQuantity = 'Quantity must be a Valid number';
        }
      }

      console.log("ValidateError", ValidateError)
      return ValidateError;
    } catch (err) { console.log("validation err", err) }
  };

  //NFT Form submit function
  const FormSubmit = async () => {
    SetFormButton("process");
    SetValidateError({});
    const id = toast.loading("Validating Form");
    console.log("valssss", NFTFormValue);
    var Error = await Validation(NFTFormValue);
    console.log("Form error", Error);
    if (isEmpty(Error)) {
      console.log("validation passed")
      SetFormButton("process");
      console.log("All clear");
      let Resp = await nftNameValidation({
        NFTName: NFTFormValue.NFTName,
      });
      console.log("name validation", Resp)
      toast.update(id, {
        render: Resp.success == "success" ? "Ready To Mint" : "Check Fields",
        type: Resp.success,
        isLoading: false,
        autoClose: 1000,
      });
      if (Resp?.success == "success") {
        let Respc = await ContractCall.Contract_Base_Validation();
        console.log("base validation", Respc)

        if (!Respc) {
          console.log("approve status input", location,
            NFTFormValue.ContractAddress)

          let Statu = await ContractCall.GetApproveStatus(
            location,
            NFTFormValue.ContractAddress
          );
          console.log("Approve status", Statu);
          if (Statu == true) {
            SetApproveButton("stop");
            SetUploadButton("start");
          } else {
            SetApproveButton("start");
            toast.update(id, {
              render: "Get APProve",
              type: "success",
              isLoading: false,
              autoClose: 1000,
            });
          }
        } else {
          SetFormButton("error");
          SetValidateError(Respc);
        }
      }
      // setShow8(true);
      window.$("#create_item_modal").modal("show");

    } else {
      SetFormButton("error");
      SetValidateError(Error);
      toast.update(id, {
        render: "Form Validation failed Check Fields ",
        type: "error",
        isLoading: false,
        autoClose: 100,
      });
    }
  };

  //NFT Initial Approve
  const TokenApproveCall = async () => {
    SetApproveButton("process");
    const id = toast.loading("Approve in process");
    // console.log("sfasfasdasdas", NFTFormValue.ContractAddress);
    const cont = await ContractCall.SetApproveStatus(
      location,
      NFTFormValue.ContractAddress
    );
    toast.update(id, {
      render: cont ? "Approved Successfully" : "Approved Failed",
      type: cont ? "success" : "error",
      isLoading: false,
      autoClose: 1000,
    });
    if (cont.status) {
      SetApproveButton("done");
      SetUploadButton("start");
    } else SetApproveButton("try");
  };


  //Upload image in IPFS
  async function UploadIPFScall() {
    const {
      NFTCreator,
      NFTThumpImage,
      NFTOrginalImage,
      NFTName,
      NFTDescription,
    } = NFTFormValue;
    SetUploadButton("process");
    const id = toast.loading("Uploading  File");
    console.log("ipfsupload", accountAddress,
      NFTThumpImage,
      NFTOrginalImage,
      NFTName,
      NFTDescription,)
    let Resp = await NFTImageUpload({
      NFTCreator: NFTCreator ? NFTCreator : accountAddress,
      NFTThumpImage,
      NFTOrginalImage,
      NFTName,
      NFTDescription,
    });
    if (Resp.success == "success") {
      console.log("Resp.data", Resp?.data);
      toast.update(id, {
        render: "Successfully Updated",
        type: Resp.success,
        isLoading: false,
        autoClose: 1000,
      });
      setNFTFormValue({ ...NFTFormValue, ...Resp.data });
      SetUploadButton("done");
      setMintButton("start");
    } else {
      SetUploadButton("try");
      toast.update(id, {
        render: "Try Again",
        type: Resp.success,
        isLoading: false,
        autoClose: 1000,
      });
    }
    // toast.update(id, {
    //   render: Resp.msg,
    //   type: Resp.success,
    //   isLoading: false,
    //   autoClose: 1000,
    // });
  }

  //NFT Mint Function
  async function MintCall() {
    const id = toast.loading("Minting Processing");
    setMintButton("process");
    let _data = NFTFormValue;
    console.log("NFTFormValue", NFTFormValue);
    _data.activity = "Mint";
    let ENc = window.btoa(JSON.stringify(_data));
    const cont = await ContractCall.minting_721_1155(
      config.IPFS + NFTFormValue.MetaData,
      [
        NFTFormValue.NFTQuantity,
        NFTFormValue.ContractType,
        web3?.utils.toWei(NFTFormValue.NFTRoyalty),
        web3.utils.toWei(
          (NFTFormValue.PutOnSaleType == "FixedPrice"
            ? NFTFormValue.NFTPrice
            : "0"
          ).toString()
        ),
        0,
      ],

      ENc
    );
    if (cont) {
      _data.HashValue = cont.HashValue;
      _data.NFTId = cont?.tokenCounts?.toString();
      _data.click = `${config.FRONT_URL}/info/${NFTFormValue.CollectionNetwork
        }/${NFTFormValue.ContractAddress
        }/${accountAddress}/${cont?.tokenCounts?.toString()}`;
      console.log("final data after mintin", _data)


      let Resp = await CreateNFT(_data);
      console.log("REsp after mint", Resp)
      // toast.update(id, {
      //   render: Resp.msg,ipfs
      //   type: Resp.success,
      //   isLoading: false,
      //   autoClose: 1000,
      // });
      if (Resp.success == "success") {
        toast.update(id, {
          render: "Successfully Minted",
          type: Resp.success,
          isLoading: false,
          autoClose: 1000,
        });
        setMintButton("done");
        window.$("#closemint").click()
        window.$("#create_item_modal").modal("hide");
        history.push("/explore/All");

        // push("/user/" + payload.CustomUrl ? payload.CustomUrl  : payload.ProfileUrl  );
      } else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        setMintButton("try");
      }
    } else {
      toast.update(id, {
        render: "Transaction Failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
      setMintButton("try");
    }
  }

  const CollectionByCreate = async (data) => {
    let Resp = await CollectionByCreator(data);
    SetCreateCollectionState(Resp?.data);
  };

  const signcall = async () => {
    const id = toast.loading("Validating Form");
    console.log("valssss", NFTFormValue);
    let Error = await Validation(NFTFormValue);
    console.log("Form error", Error);
    if (isEmpty(Error)) {
      console.log("validation passed")
      SetFormButton("process");
      console.log("All clear");
      let Resp = await nftNameValidation({
        NFTName: NFTFormValue.NFTName,
      });
      console.log("name validation", Resp)
      toast.update(id, {
        render: Resp.success == "success" ? "Ready To Mint" : "Check Fields",
        type: Resp.success,
        isLoading: false,
        autoClose: 1000,
      });
      if (Resp?.success == "success") {
        let Respc = await ContractCall.Contract_Base_Validation();
        console.log("base validation", Respc)
        SetFormButton("process");
        const id = toast.loading("Signature Call", { closeButton: true, theme: "light" });
        // var generator = require('generate-password');
        // console.log("qweqweqwewqeqweqwezxccvc", Wallet_Details, NFTDetails);
        if (web3) {
          // var web3Rpc = new Web3(config.RPC_URL)
          // console.log("qweqwewqewqewqewqqwvbv", web3Rpc);
          var randomNum = randomInteger(10000000, 100000000);
          console.log("sdcfsdfsdfsdvsdfdsf", randomNum);
          let password = "Bidex"
          // var password = generator?.generate({
          //   length: 10,
          //   numbers: true
          // });
          console.log("asdasdasdas", password);
          if (web3) {
            SetSignTxt('On progress')
            setCloseStatus('On progress')
            setApproveCallStatus('process')
            // console.log("checking window.ethereum", web3.givenProvider,Wallet_Details.providerss)
            var web3RpcPro = new Web3(web3.providers);
            console.log("asddfsfsdvbvbvcb", web3RpcPro, accountAddress);
            const to = accountAddress
            // var Price = web3RpcPro.utils.toWei(String(NFTDetails.TokenPrice));
            const _amount = NFTFormValue.NFTPrice == "" || NFTFormValue.NFTPrice == undefined ? 0 : web3RpcPro.utils.toWei(String(NFTFormValue.NFTPrice));
            // const _message = "Presale NFT";
            // 0
            console.log("ajhghjas", _amount);
            const _nonce = Date.now();
            // setNonceHash(_nonce);
            console.log("qwewqewqreqwrqrq", password);

            setRandomName(password)
            var tot = _nonce + Number(randomNum);
            setNonceHash(tot);
            const result = web3RpcPro.utils.soliditySha3(to, _amount, password, tot);
            const signhash = await web3.eth.personal.sign(result, to);
            if (signhash) {
              // SetSignTxt('done')
              toast.update(id, {
                render: "Approved Successfully",
                type: "success",
                isLoading: false,
                autoClose: 2500,
                closeButton: true,
                theme: "light"
              });
              // setPurchaseCallStatus('init')
              setSignatureHash(signhash);
              lazymint({ _nonce: tot, Randomname: password, signature: signhash })
              // let Respsign = await signcallaxios({ accountAddress: accountAddress, Randomname: password, _nonce, signature: signhash });
              // console.log("hsjdhhja", Respsign);
              // if (Respsign) {
              //   setSign(true)
              //   lazymint(Respsign)

              // }
            }
          }
        }
      }
    } else {
      SetFormButton("error");
      SetValidateError(Error);
      toast.update(id, {
        render: "Form Validation failed Check Fields ",
        type: "error",
        isLoading: false,
        autoClose: 100,
      });
    }
  }
  // console.log("8uu8u8yu8",Randomname);

  const lazymint = async (signdata) => {

    const id = toast.loading("Uploading in server", { closeButton: true, theme: "light" });
    const {
      NFTCreator,
      NFTThumpImage,
      NFTOrginalImage,
      NFTName,
      NFTDescription,
    } = NFTFormValue;
    let Resp = await NFTImageUpload({
      NFTCreator: NFTCreator ? NFTCreator : accountAddress,
      NFTThumpImage,
      NFTOrginalImage,
      NFTName,
      NFTDescription,
    });
    console.log("fdfdfdf", Resp, NFTFormValue);
    var data = Resp.data;
    if (data) {
      const formData = new FormData()

      formData.append("NFTOrginalImage", data?.NFTOrginalImage);
      formData.append("NFTName", NFTFormValue.NFTName);
      formData.append("NFTPrice", NFTFormValue.NFTPrice);
      formData.append("PutOnSaleType", NFTFormValue.PutOnSaleType);
      formData.append("TokenDescription", NFTFormValue.NFTDescription);
      formData.append("TokenProperties", NFTFormValue.NFTProperties);
      formData.append("ClockTime", NFTFormValue.ClockTime);
      formData.append("EndClockTime", NFTFormValue.EndClockTime);
      formData.append("UnLockcontent", NFTFormValue.UnlockContent)
      formData.append("MinimumBid", NFTFormValue.NFTMinimumBid)
      formData.append("Category", NFTFormValue.Category)
      formData.append("NFTRoyalty", NFTFormValue.NFTRoyalty)
      formData.append("NFTQuantity", NFTFormValue.NFTQuantity)
      formData.append("CoinName", NFTFormValue.CoinName)
      formData.append("CollectionName", NFTFormValue.CollectionName)
      formData.append("CollectionNetwork", NFTFormValue.CollectionNetwork)
      formData.append("NFTOwner", NFTFormValue.NFTOwner)
      formData.append("Hashvalue", NFTFormValue.HashValue)
      formData.append('CompressedFile', data.CompressedFile)
      formData.append('NFTCreator', NFTFormValue.NFTCreator)
      formData.append('ContractType', NFTFormValue.ContractType)
      formData.append("PutOnSale", NFTFormValue.PutOnSale)
      formData.append("ContractAddress", NFTFormValue.ContractType == "721" ? network[Wallet_Details.currentChainId]?.singleContract : network[Wallet_Details.currentChainId]?.multipleContract)
      formData.append("activity", "Mint")
      formData.append("status", "Lazymint")
      formData.append("ChainId", NFTFormValue.ChainId)
      formData.append("MetFile", data.MetFile)
      formData.append("MetaData", data.MetaData)
      formData.append("CollectionSymbol", NFTFormValue.CollectionSymbol)
      formData.append("NFTThumpImage", data.NFTThumpImage)
      formData.append("NFTOrginalImageIpfs", data.NFTOrginalImageIpfs)
      formData.append("NFTThumpImageIpfs", data.NFTThumpImageIpfs)
      formData.append("CompressedThumbFile", data.CompressedThumbFile)
      formData.append("Randomname", signdata.Randomname)
      formData.append("_nonce", signdata._nonce)
      formData.append("signature", signdata.signature)

      console.log("huybuiyui", formData.get("Randomname"));
      let Resps = await lazymintaxios(formData);
      console.log("lazy mint Db data", Resps)
      if (Resps) {
        history.push("/explore/All");
        toast.update(id, {
          render: "NFT Created Successfully",
          type: "success",
          isLoading: false,
          autoClose: 2500,
          closeButton: true,
          theme: "light"
        });
      }
    } else {
      toast.update(id, {
        render: "Error in Server",
        type: "error",
        isLoading: false,
        autoClose: 2500,
        closeButton: true,
        theme: "light"
      });
    }

  }

  const CurrecyChange = () => {
    if (NFTFormValue.PutOnSaleType === "FixedPrice") NFTFormValue.CoinName = currency[0]?.label
    else if (NFTFormValue.PutOnSaleType === "TimedAuction") NFTFormValue.CoinName = currency[1]?.label
    else NFTFormValue.CoinName = ""
  }

  const DiscardAll = () => {
    setNFTFormValue(initialTokenValue);
    // NFTPrice.val('')
  }

  const checkPutOnSale = (putonsale) => {
    console.log("putondale status", putonsale)
    if (!putonsale) {
      setNFTFormValue({
        ...NFTFormValue,
        ...{
          ["PutOnSale"]: putonsale,
          ["PutOnSaleType"]: "UnlimitedAuction",
          ["NFTPrice"]: 0,
          ["NFTMinimumBid"]: 0,
          ["ClockTime"]: "",
          ["EndClockTime"]: "",
          ["CoinName"]: ""
        }
      })
    } else {
      setNFTFormValue({
        ...NFTFormValue,
        ...{
          ["PutOnSale"]: putonsale
        }
      })
    }
  }




  /// calendar Functionalities

  var yesterday = moment().subtract(1, "day");
  const [AucCalendar, setAucCalender] = useState("Start");   // to select start or end calendar
  const [PicStartselected, setPicStartselected] = useState(false);  // to cehck if specfic date picked in start listing
  const [AuctionStart, setAuctionStart] = useState(null);  //  state to maintain start clocktime
  const [AuctionEnd, setAuctionEnd] = useState(null);      //  state to maintain End clocktime



  const CalAction = (a, day) => {
    var myDate = new Date();
    var newdat = myDate.setDate(myDate.getDate() + parseInt(day));
    var newdate = new Date(newdat)
    console.log("date not picekd", newdate)
    if (a === "now") {
      setAuctionStart(newdate);
      setNFTFormValue({
        ...NFTFormValue,
        ...{
          ["ClockTime"]: newdate,
        },
      });
    } else {
      setAuctionEnd(newdate);
      setNFTFormValue({
        ...NFTFormValue,
        ...{
          ["EndClockTime"]: newdate
        }
      });
    }
  };

  // ------> isvalidate in calender_modal (to restrict date selection)
  var valid = function (current) {
    if (AucCalendar === "Start" && AuctionEnd === null)
      return current.isAfter(yesterday);
    else if (AuctionStart !== null)
      return current.isAfter(new Date(AuctionStart));
    else if (AuctionEnd !== null)
      return current.isAfter(yesterday) && current.isBefore(new Date(AuctionEnd));
    else
      return current.isAfter(moment());
  };

  //-----> onchange function for date picker
  const Auctionset = (date) => {
    var datesel = (new Date(date))
    console.log("AucCalendar", AucCalendar)
    if (AucCalendar === "Start") {
      setAuctionStart(datesel)
      setNFTFormValue({
        ...NFTFormValue,
        ...{
          ["ClockTime"]: datesel,
        },
      });
    } else {
      setAuctionEnd(datesel)
      setNFTFormValue({
        ...NFTFormValue,
        ...{
          ["EndClockTime"]: datesel,
        },
      });
    }
  };

  const changeChain = async (e) => {
    const chainid = e.networkid;
    console.log('jdfhjdhgfgdag', e);
    console.log("chain id", Wallet_Details?.currentChainId, Number(chainid))
    var isChainChanged = await checkChain(Number(Wallet_Details?.currentChainId), Number(chainid), "switch")

    console.log("chainstatus", isChainChanged, chainid, Wallet_Details?.currentChainId)
    if (isChainChanged == true) {
      // setNFTFormValue({...NFTFormValue,...{CollectionNetwork: e.label, ChainId: chainid}})	
      //  var ContractAddressUser_val = (urllocation.pathname.split("/")[1] == "create-single") ? network[String(chainid)]?.singleContract : network[String(chainid)]?.multipleContract;	
      //  console.log("chain contract address",ContractAddressUser_val)	
      // setCoinNames("")	
      // setChainId(chainid)	
      // set_ContractAddressUser(ContractAddressUser_val)	
    }
    else if (isChainChanged) {
      var Allnetwork = await getAllChain()
      console.log("asdnjaksdsakndas", Allnetwork);
      var curr = Allnetwork.data.filter(item => item.label == e.label)
      console.log("sdadasdasdas", curr[0], e.label);
      var FinalCurrency = curr[0]?.CurrencyDetails.filter(item => (item.deleted != true || item.deleted != 1))
      console.log("finafddd", FinalCurrency);
      if (Allnetwork?.status == true) {
        isChainChanged.chainList = Allnetwork?.data;
      }
      if (e.label !== "Binance") {
        setmin(false)
      }
      toast.success(e.label + " Network Switched Successfully", { autoClose: 1000 })
      dispatch({
        type: "Account_Section",
        Account_Section: { AccountDetails: isChainChanged }

      })
      dispatch({
        type: "Register_Section",
        Register_Section: {
          currency: FinalCurrency,

        },
      });
      console.log("DFGSDFSDFSDF", { CollectionNetwork: e.label, ChainId: chainid });
      setNFTFormValue({ ...NFTFormValue, ...{ CollectionNetwork: e.label, ChainId: chainid,ContractAddress: ((urllocation.pathname.split("/")[1] == "create-single") ? network[String(chainid)]?.singleContract : network[String(chainid)]?.multipleContract) } })
    }
    else {
      toast.error("Switch to " + e.label + ' Network', { autoClose: 1000 })
    }
  }



  return (
    <div className="inner_header create_page">
      {console.log("asdffggs", NFTFormValue)}
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<>
          <div className="light_logo"><Link to="/">
            <img src={require("../assets/img/logo.png")} alt="logo" className="img-fluid" /></Link></div>
          <div className="dark_logo"><Link to="/">
            <img src={require("../assets/images/dark-theme-logo.png")} alt="logo" className="img-fluid" /></Link></div>
        </>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        {console.log("creat page nft", sellerFees)}
        <div className="container-fluid container-theme">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="d-flex align-items-center">
                <Link to="/create">
                  <i className="fas fa-arrow-left mr-3 arrow_back"></i>
                </Link>
                <h3 className="section-head mb-0 sec_he_sm">{location === "Single" ? 'Create Single Collectible' : "Create Multiple Collectible"}</h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className="container-fluid container-theme mt-5">
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <div className="text-center">
                <label className="primary_label">Preview</label>
              </div>
              <div className="tokencards_pos single_card mb-5">
                <div className="card_bg">

                  <div className="card_img_high">
                    {(fileType == "video") ?
                      <video
                        src={previewFile}
                        alt="collections"
                        autoPlay={true}
                        muted={true}
                        controls /> :
                      (fileType == "audio") ?
                        <audio
                          src={previewFile}
                          alt="collections"
                          autoPlay={false}
                          muted={true}
                          controls /> :
                        <img src={previewFile} className="img-fluid" />}
                  </div>


                  <div className="miketrout mt-5">
                    {payload?.Profile ? <img
                      src={payload?.Profile ? `${config.IMG_URL}/user/${payload?.WalletAddress}/profile/${payload?.Profile}` : <></>}
                      alt="images"
                    /> : <img src={Profile} />}
                    <div className="miketroutMba">
                      <p className="name mt-0">{(NFTFormValue?.NFTName) ? NFTFormValue?.NFTName : "leborn James"}</p>
                      <p className="coins">Hell-A-Lot</p>
                    </div>
                  </div>
                  <div className="staricons">
                    <div className="bnbgs"><img src={BNB} /><span>{(NFTFormValue?.NFTPrice) ? (String(NFTFormValue?.NFTPrice) + " " + String(NFTFormValue?.CoinName)) : "40 BNB"}</span></div>
                    {/* <div className="bnbgs"><img src={Star} /><span>{NFTFormValue?.NFTPrice}</span></div> */}
                  </div>
                </div>
              </div>
              {(fileType == "video" || fileType == "audio") &&
                <>
                  <div className="text-center">
                    <label className="primary_label">Thumbnail</label>
                  </div>
                  <div className="tokencards_pos mb-5">
                    <div className="card_bg">
                      <div className="band_name mb-3">
                        <img src={Lakers} />
                        <div className="band_nameMba">
                          <p>MBA</p>
                        </div>
                      </div>
                      <div className="card_img_high">
                        <img src={previewThumbnail} />
                      </div>


                      <div className="miketrout">

                        <img src={Profile} />
                        <div className="miketroutMba">
                          <h3>leborn James</h3>
                          <h5>Hell-A-Lot</h5>
                        </div>
                      </div>
                      <div className="staricons">
                        <div className="bnbgs"><img src={BNB} /><span>40k</span></div>
                        <div className="bnbgs"><img src={Star} /><span>25/526</span></div>
                      </div>
                    </div>
                  </div></>}
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <form className="formCls">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Upload file</label>
                        <p className="form_note">JPG, JPEG, PNG, GIF, WEBP, WEPM, OGV, MP3, FLAC, AAC or MP4 . Max 50mb.</p>
                      </div>
                      <div className="file_btn btn primary_btn">Upload
                        <input
                          className="inp_file"
                          type="file"
                          name="file"
                          id="NFTOrginalImage"
                          accept="audio/*,video/*,image/*"
                          onChange={(e) => onChange(e, "Orginal")}

                        />
                      </div>

                    </div>
                    {ValidateError.NFTOrginalImage && (
                      <span className="text-danger img-file">
                        {ValidateError.NFTOrginalImage}
                      </span>
                    )}
                  </div>

                </div>
                <div className="form-row">

                  <div className="form-group col-md-12">
                    {console.log('jdgfdsfdsa', Wallet_Details)}
                    <label className="primary_label" htmlFor="category">Select Network</label>
                    <Select
                      className="yes1 form-control primary_inp select1 selxet_app"
                      id="tokenCategory"
                      name="tokenCategory"
                      isSearchable={false}
                      maxMenuHeight={220}
                      classNamePrefix="react-select"
                      // menuIsOpen={true}
                      options={Wallet_Details?.chainList}
                      onChange={(e) => changeChain(e)}
                      label="Select Network"
                      placeholder="Select Network"
                      value={{
                        label: NFTFormValue.CollectionNetwork,
                        value: NFTFormValue.CollectionNetwork
                      }}
                    // formControlProps={{
                    //   fullWidth: true
                    // }}
                    />
                    {ValidateError.Category && (
                      <span className="text-danger img-file">
                        {ValidateError.Category}
                      </span>
                    )}
                  </div>

                </div>
                {NFTFormValue.CollectionNetwork == "Binance" &&
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <div className="d-flex justify-content-between align-items-center grid_toggle mb-4">

                        <label className="primary_label mt-4" htmlFor="inputEmail4"
                        >Lazymint</label>
                        <label className="switch toggle_custom ">
                          <input
                            type="checkbox"
                            id=""
                            name=""
                            autoComplete="off"
                            onChange={() => {

                              setmin(!lazy)


                            }}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                    </div>
                  </div>}





                {(fileType == "video" || fileType == "audio") &&
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <div className="d-flex justify-content-between align-items-start">
                        <div>
                          <label className="primary_label" htmlFor="inputEmail4">Upload Thumbnail</label>
                          <p className="form_note">JPG, JPEG, PNG. Max 50mb.</p>
                        </div>
                        <div className="file_btn btn primary_btn"> Upload Thumbnail
                          <input
                            className="inp_file"
                            type="file"
                            name="file"
                            id="NFTThumpImage"
                            accept="audio/*,video/*,image/*"
                            onChange={(e) => { onChange(e, "Thump") }}

                          />
                        </div>

                      </div>
                      {ValidateError.NFTThumpImage && (
                        <span className="text-danger img-file">
                          {ValidateError.NFTThumpImage}
                        </span>
                      )}
                    </div>


                  </div>}

                {/* //// putonsale section */}
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4">Put on Sale</label>
                        <p className="form_note">You’ll receive bids on this item</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input type="checkbox"
                          id="putonsale"
                          name="putonsale"
                          checked={NFTFormValue.PutOnSale}
                          onChange={() => {
                            console.log("check putonsale")
                            checkPutOnSale(!NFTFormValue.PutOnSale)
                          }
                          }



                        />
                        <span className="slider"></span>
                      </label>
                    </div>

                    {/* //// put on sale options */}
                    {NFTFormValue?.PutOnSale &&

                      <>
                        <div className="row connect_row mt-3 putonsale_sec">
                          <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                            <div className={"create_box create_sing_bx"} id="fixedprice"
                              onClick={() =>
                                setNFTFormValue({
                                  ...NFTFormValue,
                                  ...{
                                    ["PutOnSaleType"]: "FixedPrice",
                                    ["NFTPrice"]: 0,
                                    ["ClockTime"]: "",
                                    ["EndClockTime"]: "",
                                    ["CoinName"]: ""

                                  },
                                })
                              } >
                              <img src={require("../assets/images/price_svg.svg").default} alt="Fixed Price" className="img-fluid" />
                              <p>Fixed Price</p>
                            </div>
                          </div>
                          {location === 'Single' &&
                            <>
                              {
                                <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                                  <div className={"create_box create_sing_bx"}
                                    onClick={() =>
                                      setNFTFormValue({
                                        ...NFTFormValue,
                                        ...{
                                          ["PutOnSaleType"]: "TimedAuction",
                                          ["NFTPrice"]: 0,
                                          ["ClockTime"]: "",
                                          ["EndClockTime"]: "",
                                          ["CoinName"]: ""

                                        },
                                      })
                                    }>
                                    <img src={require("../assets/images/timed_svg.svg").default} alt="Timed Auction" className="img-fluid" />
                                    <p>Timed Auction</p>
                                  </div>
                                </div>
                              }
                            </>}
                          <div className="col-12 col-sm-4 col-md-12 col-lg-4 mb-3">
                            <div className={"create_box create_sing_bx"} onClick={() =>
                              setNFTFormValue({
                                ...NFTFormValue,
                                ...{
                                  ["PutOnSaleType"]: "UnlimitedAuction",
                                  ["NFTPrice"]: 0,
                                  ["ClockTime"]: "",
                                  ["EndClockTime"]: "",
                                  ["CoinName"]: ""

                                },
                              })
                            }>
                              <img src={require("../assets/images/unlimited_svg.svg").default} alt="Unlimited Auction" className="img-fluid" />

                              <p>Unlimited Auction</p>
                            </div>
                          </div>
                        </div>

                      </>}

                    {/* // fixed Price section */}

                    {NFTFormValue?.PutOnSaleType == "FixedPrice" &&
                      <div className="row mx-0 mt-3 fixed_price_sec">

                        <label className="primary_label" htmlFor="price_new">Price</label>


                        <div className="form-row w-100">

                          <div className="form-group col-md-6">
                            <div className="input-group input_grp_style_1">


                              <input
                                type="number"
                                className="form-control selct_form_input_h sdfsdfsdfljsdl"
                                autoComplete="off"

                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                name="TokenPrice"
                                step="0.01"
                                maxLength={15}
                                // autoComplete="off"

                                id="NFTPrice"
                                onChange={onChange}
                                // value={NFTFormValue?.NFTPrice}
                                onWheel={(e) => e.target.blur()}
                              />


                              <div className="input-group-append">
                                <Select
                                  className="yes1 form-control primary_inp select1 selxet_app"
                                  id="basic-addon2"
                                  name="coinname"
                                  options={currency}
                                  label="Select price"
                                  isSearchable={false}
                                  onChange={(e) =>

                                    setNFTFormValue({
                                      ...NFTFormValue,
                                      ...{ ["CoinName"]: e.label },
                                    })

                                  }
                                // formControlProps={{
                                //   fullWidth: true
                                // }}
                                />

                              </div>


                            </div>
                            <div className="d-flex justify-content-between">
                              {ValidateError.NFTPrice && (
                                <span className="text-danger img-file">{ValidateError.NFTPrice}</span>

                                // <span className="text-danger img-file">
                                //   {ValidateError.NFTPrice}
                                // </span>
                              )}
                              {ValidateError.CoinName && (
                                <span className="text-danger img-file ml-auto">
                                  {ValidateError.CoinName}
                                </span>
                              )}
                            </div>



                          </div>
                        </div>

                        <p className="form_note">Service fee
                          <span className="font_we_700_note_txt">{web3?.utils?.fromWei(sellerFees)}%</span><br />
                          You will receive <span className="font_we_700_note_txt">
                            {YouWillGet}
                            {/* <Convert
                            /> */}
                            {NFTFormValue?.CoinName}
                          </span>

                          {/* <span className="font_we_700_note_txt">
                            <br />
                        


                            $ 0
                          </span> */}
                        </p>

                      </div>

                    }


                    {/* ////   Timed auction section */}
                    {location === 'Single' &&
                      <>
                        {NFTFormValue?.PutOnSaleType == "TimedAuction" &&

                          <div className="row mt-3 timed_sec">
                            <div className="col-12 mb-3">
                              <label className="primary_label" htmlFor="price_new">Minimum bid</label>
                              {ValidateError.NFTPrice && (

                                <span className="text-danger img-file mini_error">
                                  {ValidateError.NFTPrice}
                                </span>
                              )}
                              <div className="input-group mb-1">
                                <input
                                  type="number"
                                  className="form-control selct_form_input_h"
                                  placeholder="Enter minimum bid"
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon3"
                                  name="MinimumBid"
                                  maxLength=""
                                  autoComplete="off"

                                  id="NFTPrice"
                                  onChange={onChange}
                                  // value={NFTFormValue.NFTPrice}
                                  onWheel={(e) => e.target.blur()}
                                />
                                <div className="input-group-append">
                                  <Select
                                    className="yes1 form-control primary_inp select1 selxet_app"
                                    id="basic-addon2"
                                    name="coinname"
                                    options={currency.filter(item => item.label !== network[Wallet_Details.currentChainId]?.currencySymbol)}
                                    label="Select price"
                                    isSearchable={false}

                                    onChange={(e) =>
                                      setNFTFormValue({
                                        ...NFTFormValue,
                                        ...{ ["CoinName"]: e.label },
                                      })
                                    }
                                  // formControlProps={{
                                  //   fullWidth: true
                                  // }}
                                  />
                                </div>
                                {/* {ValidateError.NFTPrice && (
                                        <span className="text-danger img-file">
                                          {ValidateError.NFTPrice}
                                        </span>
                                      )} */}
                                {ValidateError.CoinName && (
                                  <span className="text-danger img-file">
                                    {ValidateError.CoinName}
                                  </span>
                                )}

                                {/* <div className="input-group-append">
                              <p className="yes1 form-control primary_inp select1 selxet_app">symbol</p>
                            </div> */}
                              </div>
                              <p className="form_note">   Bids below this amount won't be allowed. If you not enter any amount we will consider as 0</p>
                            </div>

                            <div className="col-12 col-lg-6  mb-3">
                              <div className="single_dd_1">
                                <label className="primary_label" htmlFor="start_date">Starting Date</label>
                                <div className="dropdown">
                                  <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                    {AuctionStart ? (AuctionStart).toLocaleString() : "Starting Date"}
                                    <i className="fas fa-chevron-down"></i>
                                  </button>
                                  <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton" >
                                    {/* <div >Select Date</div> */}
                                    <div id="RightAfterListing" onClick={() => { setPicStartselected(false); CalAction("now", 0) }}
                                    >Right after listing</div>
                                    <div id="PickStart" onClick={() => {
                                      setAucCalender("Start"); setPicStartselected(true)
                                      window.$("#calendar_modal").modal("show")

                                    }} >Pick specific date</div>
                                  </div>
                                </div>

                              </div>
                              {ValidateError.ClockTime && (
                                <span className="text-danger img-file date_error">
                                  {ValidateError.ClockTime}
                                </span>
                              )}
                            </div>
                            <div className="col-12 col-lg-6 mb-3">
                              <div className="single_dd_1">
                                <label className="primary_label" htmlFor="start_date">Expiration Date</label>
                                <div className="dropdown">
                                  <button className="btn btn-secondary dropdown-toggle filter_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {AuctionEnd ? (AuctionEnd).toLocaleString() : "Expiration Date"}
                                    <i className="fas fa-chevron-down"></i>
                                  </button>
                                  <div className="dropdown-menu filter_menu" aria-labelledby="dropdownMenuButton">
                                    {/* <div>Select Date</div> */}
                                    {!PicStartselected &&
                                      <>
                                        <div onClick={() => CalAction("end", 1)}>1 day</div>
                                        <div onClick={() => CalAction("end", 2)}>2 days</div></>}
                                    <div onClick={() => {
                                      setAucCalender("End")
                                      window.$("#calendar_modal").modal("show")

                                    }}>Pick specific date</div>

                                  </div>
                                </div>

                              </div>
                              {ValidateError.EndClockTime && (
                                <span className="text-danger img-file date_error">
                                  {ValidateError.EndClockTime}
                                </span>
                              )}

                            </div>
                            <div className="col-12">
                              <p className="form_note_link_boild" data-toggle="modal" data-target="#learn_modal">Learn more how timed auctions work</p>

                            </div>

                          </div>


                        }</>}</div>
                  <div className="form-group col-md-12">
                    <div className="d-flex justify-content-between align-items-start grid_toggle">
                      <div>
                        <label className="primary_label" htmlFor="inputEmail4"
                        >Unlock Once Purchased</label>
                        <p className="form_note">Content will be unlocked after successful transaction</p>
                      </div>
                      <label className="switch toggle_custom">
                        <input
                          type="checkbox"
                          id="unlockoncepurchased"
                          name="unlockoncepurchased"
                          autoComplete="off"
                          onChange={() => {
                            setUnlock(!unlock)
                          }}
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                    {unlock &&
                      <div className="form-group unlock_sec">
                        <input type="text"
                          className="form-control primary_inp mb-1"
                          name="UnLockcontent"
                          id="UnLockcontent"
                          autoComplete="off"
                          placeholder="Digital key, code to redeem or link to a file.."
                          onChange={(e) => {
                            setNFTFormValue({
                              ...NFTFormValue,
                              ...{
                                ["UnlockContent"]: e.target.value,
                              },
                            });
                          }}
                        />

                        <p className="form_note">Tip: Markdown syntax is supported</p>
                      </div>}




                  </div>
                </div>

                {/* //// form section */}

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="name">Name</label>

                    <input type="text"
                      className="form-control primary_inp"
                      onChange={onChange}
                      id="NFTName"
                      placeholder="Name your item"
                    // value={NFTFormValue.NFTName}
                    />
                    {ValidateError.NFTName && (
                      <span className="text-danger img-file">
                        {ValidateError.NFTName}
                      </span>
                    )}
                  </div>


                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="desccription">Description <span className="text-muted">(Optional)</span></label>
                    <textarea type="text"
                      className="form-control primary_inp"
                      onChange={onChange}
                      id="NFTDescription"
                      // value={NFTFormValue.NFTDescription}

                      placeholder="Description about your NFTs..."
                      autoComplete="off"
                    ></textarea>

                  </div>
                </div>

                <div className="form-row">
                  {/* <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="Collection">Select Collection</label>
                    <Select
                      className="yes1 form-control primary_inp select1 selxet_app"
                      id="userCollection"
                      name="userCollection"
                   
                      maxMenuHeight={220}
                     
                      label="Select Collection"
                      placeholder="Select Collection"
                      // formControlProps={{
                      //   fullWidth: true
                      // }}
                    />


                  </div> */}
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="royalties">Royalties</label>
                    <div className="inp_grp">
                      <input type="number"
                        className="form-control primary_inp"
                        onChange={onChange}
                        id="NFTRoyalty"
                        // value={NFTFormValue.NFTRoyalty}
                        placeholder="10%"
                        onWheel={(e) => e.target.blur()} />
                      <p className="inp_append">%</p>
                      <span>Suggested: 0%,5%, 10%, 20%. Maximum is 20%</span>


                    </div>
                    {ValidateError.NFTRoyalty && (
                      <span className="text-danger img-file">
                        {ValidateError.NFTRoyalty}
                      </span>
                    )}

                  </div>
                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="category">Category</label>
                    <Select
                      className="yes1 form-control primary_inp select1 selxet_app"
                      id="tokenCategory"
                      name="tokenCategory"
                      isSearchable={false}
                      maxMenuHeight={220}
                      classNamePrefix="react-select"
                      // menuIsOpen={true}
                      options={Categorys}
                      onChange={(e) =>
                        setNFTFormValue({
                          ...NFTFormValue,
                          ...{ ["Category"]: e.label },
                        })
                      }
                      label="Select or type name"
                      placeholder="Select or type name"
                    // formControlProps={{
                    //   fullWidth: true
                    // }}
                    />
                    {ValidateError.Category && (
                      <span className="text-danger img-file category_error">
                        {ValidateError.Category}
                      </span>
                    )}


                  </div>
                </div>
                <div className="form-row">

                  <div className="form-group col-md-6">
                    <label className="primary_label" htmlFor="properties">Properties <span className="text-muted">(Optional)</span></label>
                    <input type="text"
                      className="form-control primary_inp"
                      onChange={onChange}
                      id="NFTProperties"
                      // value={NFTFormValue.NFTProperties}
                      autoComplete="off"
                      placeholder="e.g. size" />

                  </div>
                  {location === 'Multiple' &&
                    <div className="form-group col-md-6">
                      <label className="primary_label" htmlFor="desccription">Number of copies</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={onChange}
                        id="NFTQuantity"
                        // value={NFTFormValue.NFTQuantity}                   
                        placeholder="e.g. 1"
                        autoComplete="off"
                        maxLength={3}
                        // autoComplete="off"
                        onWheel={(e) => e.target.blur()}
                      />
                      {ValidateError.NFTQuantity && (
                        <span className="text-danger img-file">
                          {ValidateError.NFTQuantity}
                        </span>
                      )}

                    </div>

                  }



                </div>


                {lazy == false ?
                  <div>

                    <Button className="create_btn" data-toggle="model"
                      disabled={
                        FormButton == "error" ||
                          FormButton == "done" ||
                          FormButton == "process"
                          ? true
                          : false
                      }
                      onClick={
                        (FormButton == "start" ? FormSubmit : null)
                      } > {FormButton == "start" && "Create Item"}
                      {FormButton == "error" && "Error"}
                      {FormButton == "process" && "processing"}
                      {FormButton == "done" && "Done"}</Button>

                  </div> :

                  <div>
                    <Button className="create_btn" data-toggle="model"
                      disabled={
                        FormButton == "error" ||
                          FormButton == "done" ||
                          FormButton == "process"
                          ? true
                          : false
                      }

                      onClick={
                        FormButton == "start" ? signcall : null
                      } > {FormButton == "start" && "Create Item"}
                      {FormButton == "error" && "Error"}
                      {FormButton == "process" && "processing"}
                      {FormButton == "done" && "Done"}


                    </Button>
                  </div>
                }
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
      {/* Choose Collection Modal */}
      {/* <div className="modal fade primary_modal" id="choose_collection_modal" tabIndex="-1" role="dialog" aria-labelledby="choose_collection_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="choose_collection_modalLabel">Collection</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="d-flex align-items-center flex_block_mob">
                <div className="hex center">
                  <div className="hex__shape">
                    <div className="hex__shape">
                      <div className="hex__shape">
                        <img src={require("../assets/images/img_01.png")} alt="logo" className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="font_14 font_600 line_20 mb-1 text_brown mb-3">We recommend an image of at least 400x400. Gifs work too.</p>
                  <div className="file_btn primary_btn d-inline-block btn_small">Choose File
                    <input className="inp_file" type="file" name="file" />
                  </div>
                </div>
              </div>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Display Name <span className="text-muted">(30 available)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Enter token name" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Symbol <span className="text-muted">(required)</span></label>
                    <input type="text" className="form-control primary_inp" id="desccription" placeholder="Enter token symbol" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="name">Description <span className="text-muted">(Optional)</span></label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Spread some words about token collection" />
                  </div>
                  <div className="form-group col-md-12">
                    <label className="primary_label_dark" htmlFor="desccription">Short url</label>
                    <input type="text" className="form-control primary_inp" id="desccription" defaultValue="Fayre.io/" />
                  </div>
                </div>
                <div className="text-center">
                  <Button className="create_btn btn-block">Create Collection</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* ens choose collection modal */}

      {/* create_item Modal */}
      <div className="modal fade primary_modal" id="create_item_modal" tabIndex="-1" role="dialog" aria-labelledby="create_item_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="create_item_modalLabel">Follow Steps</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="closemint">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                {ApproveButton != "stop" && (
                  <>
                    <div className="media approve_media">

                      <div className="media-body">
                        <p className="mt-0 approve_text">Approve</p>
                      </div>
                    </div>
                    <div className="text-center my-3">
                      <Button className="create_btn btn-block"
                        disabled={
                          ApproveButton == "process" || ApproveButton == "done"
                            ? true
                            : false
                        }
                        onClick={
                          ApproveButton == "start" || ApproveButton == "try"
                            ? TokenApproveCall
                            : null
                        }

                      >
                        {ApproveButton == "start" && "Start"}
                        {ApproveButton == "process" && "In-Progress"}
                        {ApproveButton == "try" && "Try-Again"}
                        {ApproveButton == "done" && "Done"}

                      </Button>
                    </div>
                  </>
                )}
                <div className="media approve_media">



                  <div className="media-body">
                    <p className="mt-0 approve_text">Upload Meta Data</p>
                    <p className="mt-0 approve_desc">Upload meta contents to ipfs</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="create_btn btn-block"
                    disabled={ApproveButton == "start" ||
                      UploadButton == "process" || UploadButton == "done"
                      ? true
                      : false
                    }
                    onClick={
                      UploadButton == "start" || UploadButton == "try"
                        ? UploadIPFScall
                        : null
                    }


                  >
                    {UploadButton == "stop" && "Upload"}
                    {UploadButton == "start" && "Start"}
                    {UploadButton == "process" && "In-Progress"}
                    {UploadButton == "try" && "Try-Again"}
                    {UploadButton == "done" && "Done"}

                  </Button>
                </div>
                <div className="media approve_media">

                  <div className="media-body">
                    <p className="mt-0 approve_text">Upload files & Mint token</p>
                    <p className="mt-0 approve_desc">Call contract method</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="create_btn btn-block"
                    disabled={
                      ApproveButton == "start" ||
                        UploadButton == "start" ||
                        MintButton == 'stop' ||
                        MintButton == "process" ||
                        MintButton == "done"
                        ? true
                        : false
                    }
                    onClick={
                      MintButton == "start" || MintButton == "try"
                        ? MintCall
                        : null
                    }

                  >
                    {MintButton == "stop" && "Mint"}

                    {MintButton == "start" && "Start"}
                    {MintButton == "process" && "In-Progress"}
                    {MintButton == "try" && "Try-Again"}
                    {MintButton == "done" && "Done"}
                  </Button>
                </div>


              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end create_item modal */}


      {/* learn Modal */}
      <div className="modal fade primary_modal" id="learn_modal" tabIndex="-1" role="dialog" aria-labelledby="learn_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="learn_modalLabel">How timed auction work</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>When you put your item on timed auction, you choose currency, minimum bid, starting and ending dates of your auction.</p>

              <p>The bidder can only place a bid which satisfies the following conditions:</p>
              <ol>
                <li>
                  It is at least minimum bid set for the auction
                </li>
                <li>
                  It is at least 5% higher than the current highest bid

                </li>
              </ol>

              <p>Note that some bids may disappear with time if the bidder withdraws their balance. At the same time, some bids may reappear if the bidder has topped up their balance again.</p>

              <p>Auction cannot be cancelled after any valid bid was made. Any bid placed in the last 10 minutes extends the auction by 10 minutes.</p>

              <p>In the 48 hours after the auction ends you will only be able to accept the highest available bid placed during the auction. As with regular bids, you will need to pay some gas to accept it.Note that you can always decrease the price of your listing for free, without making a transaction or paying gas. You can view all your items here.</p>


            </div>
          </div>
        </div>
      </div>
      {/* end learn modal */}

      {/* calendar Modal */}
      <div className="modal fade primary_modal" id="calendar_modal" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>
              <button type="button" id="closecalender" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-3">

                <Datetime
                  input={false}
                  isValidDate={valid}
                  timeFormat="HH:mm:ss"
                  timeConstraints={{
                    hours: { min: new Date(Date.now()).getHours(), max: 23 },
                  }}
                  onChange={(value) => {
                    Auctionset(value);
                  }}
                />
              </div>
              <div className="text-center pb-3">
                <Button className="btn btn_blue" id="doneStartDate" onClick={() => {
                  window.$("#closecalender").click();
                }}>  Done</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end calendar modal */}
      {/* calendar Modal */}
      {/* <div className="modal fade primary_modal" id="calendar_modal_expire" tabIndex="-1" role="dialog" aria-labelledby="calendar_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="calendar_modalLabel">Choose date</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-3">
                <Datetime
                  input=""
                  value=""
             
                />
              </div>
            </div>
            <div className="text-center">
              <Button className="primary_btn" id="doneEndDate">Done</Button>
            </div>
          </div>
        </div>
      </div> */}




    </div>
  );
}




















// ["https://bidexnft.infura-ipfs.io/ipfs/Qmapfw6BS62CoAiWUwftXXzsXCmsGErkkZbzVgRxHNGZvB","","","0x59fa7561cc32c9f56bc24ae8edc98ad58bae182ff6607cc73bb55f21785045c02fb9523947d5e9cefeb536205903e07f5bcbed8ce734dafcc52a80cbd3778a021b"]
// [0x98dca2725c9571b43819b31cd376dc3557e9b047,0x52b41caf8e3380dde6c9fb0243c19f06b63fb043]
// [1,721,1000000000000000000,1000000000000000000,1,1685803713923,0x59fa7561cc32c9f56bc24ae8edc98ad58bae182ff6607cc73bb55f21785045c02fb9523947d5e9cefeb536205903e07f5bcbed8ce734dafcc52a80cbd3778a021b]
// 'CAKE'
// 0x59fa7561cc32c9f56bc24ae8edc98ad58bae182ff6607cc73bb55f21785045c02fb9523947d5e9cefeb536205903e07f5bcbed8ce734dafcc52a80cbd3778a021b